<template>
  <div class="about-header">
    <div :class="{'about-header-inner': mounted}"></div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      mounted: false
    }
  },
  mounted () {
    this.mounted = true
  }
}
</script>

<style lang="stylus">
.about-header
  background #f2f5f6 url('../../assets/images/about.png') no-repeat
  background-size cover
  height 350px

.about-header-inner
  background url('../../assets/images/about_big.png') no-repeat
  background-size cover
  height 350px
</style>
