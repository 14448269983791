<template>
  <div class="jyh-about">
    <about-header></about-header>
    <about-body></about-body>
    <y-footer></y-footer>
  </div>
</template>

<script>
import AboutHeader from '../components/about/AboutHeader.vue'
import AboutBody from '../components/about/AboutBody.vue'
import YFooter from '../components/Footer.vue'
export default {
  components: { AboutHeader, AboutBody, YFooter }
}
</script>
