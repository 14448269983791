<template>
  <div class="about-body view-padding">
    <div class="title">关于银杏叶</div>
    <div class="desc">复旦科技园银杏叶临床生物样本库研究中心由银杏叶网站运营支持，成立于2011年，是国内最早一批专注于临床生物样本库（BioBank）建设领域相关产品开发的研发中心之一，运营单位为上海徽华医药科技有限公司，核心加盟单位为安徽中科都菱商用电器股份有限公司和江苏盐城金沃医疗科技有限公司。为帮助国内广大医学科研工作者提供一站式临床生物样本库建设解决方案，依托自主开发的易助手®--临床生物样本库信息管理系统（软著登字第0734748号），整合资源，实现临床生物样本库建设软件硬件一体化发展，在江苏海门建立冻存耗材生产基地，为用户定制生产冻存架、冻存盒和冻存管；与中科都菱合作为用户定制BioBank专属超低温冻存系统；整合美国贝迪冷冻标签打印系统、Terra Master云端数据存储系统、无线冷链温度监控系统、样本自动分装系统和全自动核酸提取系统，并通过“银杏叶学术讲座”平台指导临床医师规范化开展科研并合理高效使用临床生物样本库资源，同时建立规范可信的实验样本检测中心，2015年1月在国内创立“银杏叶生物样本库产业联盟”，为广大用户在标本入库前、入库中和入库后提供一整套可行可信的解决方案，有效提高了广大医院单位和临床医生的科研质量和水平。目前“银杏叶学术讲座”全国公开课已在国内成功举办21期，参会人员超过2000人，覆盖全国500多家医院的相关科室，为推动中国生物样本库的规范建设和管理作出了一定贡献。</div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css">
.about-body .title {
  text-align: center;
  font-size: 18px;
  height: 60px;
  line-height: 60px;
}
.about-body .desc {
  text-align: center;
  text-indent: 24px;
  font-size: 12px;
  line-height: 28px;
  margin-bottom: 40px;
  padding: 0 28px;
}
</style>
